<template>
    <div class="about blog">
            <div class="BlogBanner">
            </div>
            <div class="HomeMovers">
                <div class="container">
                    <h1>Blog</h1>
                    <div class="HomeMoversList fadeInUp animated">
                        <div class="MoverListLi"  v-for="(item,index) in list" :key="index" @click="gohref('/blog/'+item.blogId)">
                            <img :src="item.cover">
                            <div class="MoverTxt">{{item.title}}</div>
                        </div>                       
                    </div>
                </div>
            </div>
          <homebottom />
    </div>
  </template>
  
  <script>
  import api from '@/api'
  import homebottom from '@/components/homebottom.vue'
  // @ is an alias to /src
  
  export default {
    name: 'About',
    components: {
      homebottom
    },
    data() {
        return {
            list:[],
        }
    },
    mounted() {
        api.blogList({
            "page": 1,
            "pagesize": 10000,
        }).then(res => {
            this.list = res.data.data.dataList;
           console.log(res.data.data.dataList);
        });
    },
    methods:{
        gohref(e){
            this.$router.push(e);
        }
    }
  }
  </script>
  <style scoped>
        
      .ImgAboutli{
          position: relative;	
      }
      .playIcon{
          position: absolute;
          left: 50%;
          background-color: #c03137;
          top: 50%;
          z-index: 10;
          width: 70px;
          height: 70px;
          margin-top: -35px;
          margin-left: -35px;
          color: #fff;
          display: inline-block;
          border-radius: 200px;
          -webkit-transform: translateZ(0);
          transform: translateZ(0);	
      }
      .HomeMoversList{
        padding-bottom: 60px;
      }
      .playIcon svg {
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: 2px;
          color: #fff;
          transform: translateY(-50%) translateX(-50%);
      }
      .MoverListLi{
        cursor: pointer;
      }
  </style>