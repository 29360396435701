<template>
  <div class="about">
  		<div class="AboutBanner">
  		</div>
  		<div class="AboutTxt mobliepadd">
  			<div class="container">
  				<h1>iMover - The Melbourne Removalists Specialists</h1>
  				<p>iMover Melbourne Removalists is a comprehensive housekeeping service in Melbourne that provides piano moves, logistics, warehousing and cleaning services. Our very own Melbourne based company was founded back in 2009. iMover is a professional, friendly, and customer-oriented service. With over 13 years of moving experience and service, it has a good reputation in the industry. We have an exclusive moving team. a friendly and courteous team of movers that will take the stress out of moving. Our team of professionals will pack and move your priceless valuables with ease. Packing materials can be provided and we also have a cleaning service that will guarantee you 100% of your bond back. At iMover we have a clear charging system that is done via an app. This allows the customer to keep track of the price, and this guarantees no hidden fees.</p>
					<!-- <p>Are you looking for an affordable furniture removals service for that single item you just purchased off eBay? Call us for a quick moving quote! We can help you relocate your new item into its new home in no time.</p>
					<p>Our Cheap and Professional home move Melbourne services will never let you down. We move furniture items all over Victoria. We can even dismantle and reassemble your furniture! We are the Moving house Melbourne removalists specialists.</p> -->
  				<div class="AboutImgBox">
  					<a href="https://www.youtube.com/watch?v=xnrMluoxAB4" target="_blank" class="ImgAboutli">
  						<img src="/img/video04.jpg" class="ImgAbout">
						<span class="playIcon">
							<svg t="1706190480706" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4235" width="200" height="200"><path d="M128 138.666667c0-47.232 33.322667-66.666667 74.176-43.562667l663.146667 374.954667c40.96 23.168 40.853333 60.8 0 83.882666L202.176 928.896C161.216 952.064 128 932.565333 128 885.333333v-746.666666z" fill="#ffffff" p-id="4236"></path></svg>
						</span>
  					</a>
  					<a href="https://www.youtube.com/watch?v=q1lfAk6V13U" target="_blank" class="ImgAboutli">
  						<img src="/img/video02.png" class="ImgAbout">
						<span class="playIcon">
							<svg t="1706190480706" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4235" width="200" height="200"><path d="M128 138.666667c0-47.232 33.322667-66.666667 74.176-43.562667l663.146667 374.954667c40.96 23.168 40.853333 60.8 0 83.882666L202.176 928.896C161.216 952.064 128 932.565333 128 885.333333v-746.666666z" fill="#ffffff" p-id="4236"></path></svg>
						</span>
  					</a>
  					<a href="https://www.youtube.com/watch?v=3tv5kTVFygs" target="_blank" class="ImgAboutli">
  						<img src="/img/video01.png" class="ImgAbout">
						  <span class="playIcon">
							<svg t="1706190480706" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4235" width="200" height="200"><path d="M128 138.666667c0-47.232 33.322667-66.666667 74.176-43.562667l663.146667 374.954667c40.96 23.168 40.853333 60.8 0 83.882666L202.176 928.896C161.216 952.064 128 932.565333 128 885.333333v-746.666666z" fill="#ffffff" p-id="4236"></path></svg>
						</span>
  					</a>
  				</div>
  				<div class="AboutContBottom">
  					<p>iMover is Melbourne's very own removalist service. Our prime locations are inner and outer suburbs of Melbourne and we also regularly do regional Victoria. Interstate trips to every major city in Australia are also available. Our team is polite, courteous, and well mannered. This is in place to insure you a safe and comfortable move.</p>
  					<!-- <p>We carry all the necessary tools to dismantle and reassemble your furniture. Please note that if you assembled an item in your home, there is a great chance that it will need to be dismantled before being transported. Our professional furniture movers will advise you on the day of the move. </p>
  					<p>We use 4, 5, 8, 10 and 12-tonne trucks for all our furniture removalists jobs. </p>
  					<p>All our vehicles are fully insured and include electronic tailgate loaders and ramps. All our trucks are equipped with modern tools, equipment and technology.</p> -->
  				</div>
  			</div>
  		</div>
    	<homebottom />
  </div>
</template>

<script>
import homebottom from '@/components/homebottom.vue'
// @ is an alias to /src

export default {
  name: 'About',
  components: {
    homebottom
  },
  mounted() {
	},
}
</script>
<style scoped>
	.ImgAboutli{
		position: relative;	
	}
	.playIcon{
		position: absolute;
		left: 50%;
    	background-color: #c03137;
		top: 50%;
		z-index: 10;
		width: 70px;
		height: 70px;
		margin-top: -35px;
		margin-left: -35px;
		color: #fff;
		display: inline-block;
		border-radius: 200px;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);	
	}
	.playIcon svg {
		display: block;
		width: 20px;
		height: 20px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: 2px;
		color: #fff;
		transform: translateY(-50%) translateX(-50%);
	}
</style>