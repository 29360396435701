<template>
    <div class="about">
            <div class="AboutBanner">
            </div>
            <div class="AboutTxt mobliepadd">
                <div class="container">
                    <h1>{{ info.title}} </h1>                    
                    <div class="AboutContBottom" v-html="info.content">
                    </div>
                </div>
            </div>
          <homebottom />
    </div>
  </template>
  
  <script>
  import api from '@/api'
  import homebottom from '@/components/homebottom.vue'
  // @ is an alias to /src
  
  export default {
    name: 'About',
    components: {
      homebottom
    },
    data() {
        return {
            info:{},
        }
    },
    mounted() {
        if(this.$route.params.id){
            api.blogInfo(this.$route.params.id).then(res => {
                this.info = res.data.data;
            });
        }
    },
  }
  </script>
  <style scoped>
      .ImgAboutli{
          position: relative;	
      }
      .playIcon{
          position: absolute;
          left: 50%;
          background-color: #c03137;
          top: 50%;
          z-index: 10;
          width: 70px;
          height: 70px;
          margin-top: -35px;
          margin-left: -35px;
          color: #fff;
          display: inline-block;
          border-radius: 200px;
          -webkit-transform: translateZ(0);
          transform: translateZ(0);	
      }
      .playIcon svg {
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: 2px;
          color: #fff;
          transform: translateY(-50%) translateX(-50%);
      }
      .AboutTxt{
        text-align: left;
      }
      .AboutTxt p{
        padding-bottom: 0;
      }
  </style>